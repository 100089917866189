import { request as axios } from '@/util/request';
import { PaginationData } from '@/api/operation/customer';
const getUrl = require('baimahu/url-with-param');

const API = {
  list: '/boss/operation/promotion/limit',
  edit_sort: '/boss/operation/promotion/limit/sort',
  edit_price: '/boss/operation/promotion/limit/price',
}


// 限量数据接口
export interface LimitCountData {
  activity_id: number;
  create_time: number;
  delete_time: number;
  end_time: number;
  id: number;
  product_activity: ProductActivity;
  product_id: number;
  promotion_price: number;
  start_time: number;
  type: number;
  update_time: number;
}
interface ProductActivity {
  activity_id: number;
  create_time: number;
  id: number;
  product_id: number;
  sort: number;
  type: number;
  update_time: number;
}
// 初始化限量抢购数据
export function initLimitCountData(remote?: PaginationData<LimitCountData>): PaginationData<LimitCountData> {
  remote = remote || {} as PaginationData<LimitCountData>;
  return {
    current: +remote.current || 0,
    detail: remote.detail || [],
    last: remote.last || 0,
    limit: remote.limit || 0,
    total: remote.total || 0,
  }

}
// 获取限量列表
export async function getList(query_params: any = {
  current: 1,
  limit: 20
}) {
  const url = getUrl(API.list, query_params);
  return await axios.get(url);
}


// 新增限量抢购
export async function addLimitCount(send_data: any) {
  return await axios.post(API.list, send_data);
}


// 编辑排序
export async function editSort(id: number, sort: number) {
  return await axios.put(`${API.edit_sort}/${id}`, {
    sort
  });
}

// 删除限时抢购
export async function deleteLimitCount(product_id_list: any) {
  return await axios.delete(`${API.list}/999?product_id_list=${product_id_list}`)
}

// 编辑商品价格
export async function editPrice(id: number, promotion_price: any, product_id: any) {
  return await axios.put(`${API.edit_price}/${id}`, {
    promotion_price: promotion_price * 100,
    product_promotion_id: product_id,
  })
}