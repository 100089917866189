
import { Component, Vue, Watch } from 'vue-property-decorator';
import {
  ACTIVITY_STATUS,
  LIMIT_COUNT_WARE_STATUS,
  queryLimitTime,
  deleteLimitTime,
  LimitTimeWareData,
} from '@/api/operation/panic_buying';
import { changeLoading } from '@/util/decorators';
import { getSimpleCategory } from '@/api/ware/category';
import CustomModal from '@/component/custom-modal.vue';
import { PaginationData } from '@/api/operation/customer';
import moment, { Moment } from 'moment';
// WARE_STATUS_LIST,
import { queryWare } from '@/api/ware/management';
import {
  getList,
  initLimitCountData,
  LimitCountData,
  addLimitCount,
  editSort,
  deleteLimitCount,
  editPrice,
} from '@/api/operation/limit_count';
import EditableCell from '@/component/editable-cell.vue';
import { useGlobalFunction } from '@/pinia/modules/globalFunction';
const PANIC_BUYING_TYPE = [
  {
    id: 1,
    name: '限时抢购',
  },
  {
    id: 2,
    name: '限量抢购',
  },
];

const WARE_STATUS = [
  {
    value: 0,
    label: '全部',
  },
  {
    value: 1,
    label: '上架中',
  },
  {
    value: 2,
    label: '已下架',
  },
];

function initLimitCountFormData() {
  return {
    id: '',
    name: '',
    category: [],
    status: '0',
    time: [],
    stock_start: '',
    stock_end: '',
    price_start: '',
    price_end: '',
  };
}

function initLimitCountWareModaData() {
  return {
    id: '',
    name: '',
    category: [],
    status: 0,
  };
}

@Component({
  name: 'PanicBuying',
  components: {
    CustomModal,
    EditableCell,
  },
})
export default class PanicBuying extends Vue {
  setGlobalFunction = useGlobalFunction().setGlobalFunction;
  panic_buying_type = PANIC_BUYING_TYPE;
  activity_status = ACTIVITY_STATUS;
  limit_count_ware_status = LIMIT_COUNT_WARE_STATUS;
  is_loading: boolean = false;
  selected_index: number = 1;
  limit_time_next_page: number = 1;
  limit_time_table_data: any = {};
  limit_count_table_data: PaginationData<LimitCountData> = initLimitCountData();
  selected_id_list: number[] = [];
  ware_category_list: any[] = [];
  limit_time_filter: any = {
    activity_time: [],
    status: '0',
  };
  limit_count_filter: any = {
    ware_id: '',
    ware_name: '',
    ware_category_id: [0],
    status: '0',
    time: '',
    stock_min: '',
    stock_max: '',
    price_max: '',
    price_min: '',
  };
  is_show_ware_modal_first: boolean = false;
  is_show_ware_modal_second: boolean = false;
  delete_modal: SimpleModal = {
    title: '',
    visible: false,
    limit_time_id: 0,
  };
  selected_row_keys: any[] = [];
  batch_config: number = 0; // 批量配置模态窗 1批量配置活动价  2批量配置生效时间
  batch_config_input_value: any = ''; // 批量配置模态窗中的输入框或日期选择框的值
  ware_modal_first: SimpleModal = {
    title: '添加限量商品 第一步：选择商品',
    ok_text: '下一步',
    visible: false,
    is_loading: false,
    filter: {
      ware_id: '',
      ware_name: '',
      category_id: undefined,
      status: '0',
    },
    ware_index: 0,
    next_page: 1, // 分页数
    all_ware_data: {}, // 所有的数据
    table_list: [], // 表格数据
    selected_id_list: [], // 选中项的 id
    selected_data: [], // 选中项的数据
  };
  modal_ware_data: any = {};
  config_ware_modal_data: any = {
    detail: [],
    showTotal: '',
    total: 0,
    limit: 5,
    current: 1,
    config_limit_time: 0,
  };
  limit_count_form_data: any = initLimitCountFormData();
  limit_count_ware_modal_data: any = initLimitCountWareModaData();
  delete_limit_count_modal: boolean = false;
  delete_limit_count_id: any = '';
  selected_limit_count_row_keys: any = [];
  batch_delete: boolean = false;
  selected_data: any = [];

  get ware_status() {
    return WARE_STATUS;
  }

  get checked_all(): boolean {
    if (
      this.selected_limit_count_row_keys.length === this.limit_count_table_data.detail.length ||
      this.selected_limit_count_row_keys.length === this.limit_count_table_data.limit
    ) {
      return true;
    }
    return false;
  }

  disable_xs: boolean = false; //小数提示框是否显示
  //数字输入框change事件
  handleNumberChange(value: number) {
    //const res=/(^[0-9]\d*$)/ //正整数正则
    const ret = /^(-?\d+)(\.\d{1,2})?$/; //正则定义两位小数
    //if(types=='xs'){return}
    if (value != null && String(value) != '' && (value < 0 || !ret.test(String(value)))) {
      this.disable_xs = true;
    } else {
      this.disable_xs = false;
    }
    //this.value = value;
  }

  paginationOption(data: RemotePagination<BannerTableData>) {
    return {
      total: data.total || 0,
      showTotal: () => `共${data.total}条，每页${data.limit}条`,
      pageSize: data.limit || 10,
      current: +data.current || 1,
      config_limit_time: 0,
    };
  }
  moment(value: any) {
    return value ? moment(value).format('YYYY:MM:DD HH:mm:ss') : '';
  }
  // 点击活动价完成按钮
  clickFinish(item: any, type: number) {
    if (type === 1) {
      if (Number(item.special_offer) >= Number((item.price / 100).toFixed(2))) {
        this.$message.error('活动价须低于商品原价');
        return;
      }
    }
    // 1为活动价列的完成按钮 2为时间列的完成按钮
    type === 1 ? (item.is_edit_price = false) : (item.is_edit_time = false);
    this.$forceUpdate();
  }
  // 点击生效日期完成按钮
  clickEdit(item: any, type: number) {
    // 1为活动价列的完成按钮 2为时间列的完成按钮
    type === 1 ? (item.is_edit_price = true) : (item.is_edit_time = true);
    this.$forceUpdate();
  }

  //输入框提示判断
  money_true: boolean = false; //价格提示框显隐属性
  surplus_number: boolean = false; //剩余库存提示框显隐
  onInputNumberChange(value: number, type: string) {
    const ret = /^(-?\d+)(\.\d{1,2})?$/; //正则定义两位小数
    const res = /(^[1-9]\d*$)/; //正整数正则
    if (type == 'price_min' || type == 'price_max') {
      //传进来价格参数判断
      if (
        this.limit_count_form_data.price_start != '' &&
        this.limit_count_form_data.price_start != null &&
        (this.limit_count_form_data.price_start < 0 ||
          !ret.test(this.limit_count_form_data.price_start))
      ) {
        this.money_true = true;
      } else if (
        this.limit_count_form_data.price_end != '' &&
        this.limit_count_form_data.price_end != null &&
        (this.limit_count_form_data.price_end < 0 ||
          !ret.test(this.limit_count_form_data.price_end))
      ) {
        this.money_true = true;
      } else {
        this.money_true = false;
      }
      this.limit_count_filter[type] = value.toFixed(2);
    } else if (type == 'stock_min' || type == 'stock_max') {
      //传进来剩余库存判断
      if (
        this.limit_count_form_data.stock_start != '' &&
        this.limit_count_form_data.stock_start != null &&
        (this.limit_count_form_data.stock_start < 0 ||
          !res.test(this.limit_count_form_data.stock_start))
      ) {
        this.surplus_number = true;
      } else if (
        this.limit_count_form_data.stock_end != '' &&
        this.limit_count_form_data.stock_end != null &&
        (this.limit_count_form_data.stock_end < 0 ||
          !res.test(this.limit_count_form_data.stock_end))
      ) {
        this.surplus_number = true;
      } else {
        this.surplus_number = false;
      }
      //this.limit_count_filter[type] = +value.toFixed(0);
    }
  }

  // 商品模态窗点击勾选按钮
  onSelectChange(selectedRowKeys: any) {
    this.selected_row_keys = selectedRowKeys;
    const id_list = this.selected_data.map((item: any) => item.id);
    this.modal_ware_data.detail.forEach((item: any) => {
      if (this.selected_row_keys.indexOf(item.id) !== -1 && id_list.indexOf(item.id) === -1) {
        this.selected_data.push(item);
      }
    });
  }

  // 切换限时/限量
  async onPanicBuyingTypeChange(id: number) {
    this.selected_index = id;
    await this.fetchWareList();
    if (id === 2) {
      await this.getLimitCountData();
    }
  }

  // 更改活动价
  handlePriceChange(id: number, e: any) {
    this.config_ware_modal_data.detail.forEach((item: any) => {
      if (item.id === id) {
        item.special_offer = e * 100;
      }
    });
  }

  // 更改生效时间
  handleTimeChange(id: number, time: any) {
    this.config_ware_modal_data.detail.forEach((item: any) => {
      if (item.id === id) {
        item.time_in_force = time;
      }
    });
  }

  // 确定删除限时抢购活动
  async handleDeleteModalOk() {
    const res: any = await deleteLimitTime(this.delete_modal.limit_time_id);
    if (res.status !== 200) {
      this.$message.error(res.message);
    }
    this.delete_modal.visible = false;
    await this.fetchLimitTimeList(this.limit_time_next_page);
  }

  // 批量配置限量商品
  batchConfig() {
    if (!this.batch_config_input_value) {
      if (this.batch_config === 1) {
        this.$message.error('请输入活动价');
      } else {
        this.$message.error('请选择生效时间');
      }
      return;
    }
    if (this.batch_config === 1) {
      const price_list = this.config_ware_modal_data.detail.map((item: any) =>
        (item.price / 100).toFixed(2)
      );
      const min_price = Math.min.apply(null, price_list);
      console.log(min_price);
      if (Number(this.batch_config_input_value) > min_price) {
        this.$message.error('活动价格须低于所选商品最低价');
        return;
      }
    }

    this.config_ware_modal_data.detail.forEach((item: any) => {
      if (this.batch_config === 1) {
        item.special_offer = this.batch_config_input_value;
      } else {
        item.time_in_force = this.batch_config_input_value;
      }
    });
    this.batch_config_input_value = '';
    this.batch_config = 0;
  }

  // 点击限时抢购表格分页按钮
  @changeLoading(['is_loading'])
  async handleTableChange(pagination: any) {
    this.limit_time_next_page = pagination.current;
    this.limit_time_table_data = await queryLimitTime(this.limit_time_next_page, {
      status: this.limit_time_filter.status,
      begin:
        this.limit_time_filter.activity_time.length > 0
          ? this.limit_time_filter.activity_time[0].startOf('minute').format('X')
          : '',
      end:
        this.limit_time_filter.activity_time.length > 0
          ? this.limit_time_filter.activity_time[1].endOf('minute').format('X')
          : '',
    });
    // await this.fetchLimitTimeList(this.limit_time_next_page);
  }
  // 点击添加限量商品第一步表格分页按钮
  async handleModalTableChange(data: any) {
    this.config_ware_modal_data.current = data.current;
    // await this.fetchLimitTimeList(pagination.current);
  }
  // 点击删除按钮
  clickDeleteBtn(item: any) {
    this.delete_modal.visible = true;
    this.delete_modal.limit_time_id = item.id;
  }

  // 点击搜索按钮
  @changeLoading(['is_loading'])
  async clickSearchBtn() {
    console.log(this.limit_time_filter);
    console.log(JSON.parse(JSON.stringify(this.limit_time_filter.activity_time)));
    this.limit_time_next_page = 1;
    this.limit_time_table_data = await queryLimitTime(this.limit_time_next_page, {
      status: this.limit_time_filter.status,
      begin:
        this.limit_time_filter.activity_time.length > 0
          ? this.limit_time_filter.activity_time[0].startOf('minute').format('X')
          : '',
      end:
        this.limit_time_filter.activity_time.length > 0
          ? this.limit_time_filter.activity_time[1].endOf('minute').format('X')
          : '',
    });
  }

  // 点击查看按钮
  viewActivity(item: any) {
    this.$router.push(`/operation/add-panic-second?is_view=1&id=${item.id}`);
  }

  // 点击新增限量商品模态窗下一步按钮
  clickModalNextBtn() {
    if (this.selected_row_keys.length < 1) {
      this.$message.error('请勾选商品');
      return;
    }
    this.is_show_ware_modal_first = false;
    this.is_show_ware_modal_second = true;
    const id_list = this.config_ware_modal_data.detail.map((item: any) => item.id);
    this.selected_data.forEach((item: any) => {
      if (this.selected_row_keys.indexOf(item.id) !== -1 && id_list.indexOf(item.id) === -1) {
        this.config_ware_modal_data.detail.push(JSON.parse(JSON.stringify(item)));
      }
    });
    // for (let i = 0; i < this.modal_ware_data.detail.length; i++) {
    //   for (let j = 0; j < this.selected_row_keys.length; j++) {
    //     if (this.modal_ware_data.detail[i].id === this.selected_row_keys[j]) {
    //       const item = JSON.stringify(this.modal_ware_data.detail[i]);
    //       this.config_ware_modal_data.detail.push(JSON.parse(item));
    //     }
    //   }
    // }
    this.config_ware_modal_data.detail.forEach((item: any) => {
      item.special_offer = '';
      item.time_in_force = '';
      item.is_edit_price = false;
      item.is_edit_time = false;
    });
    this.config_ware_modal_data.total = this.config_ware_modal_data.detail.length;
    this.config_ware_modal_data.showTotal = () =>
      `共${this.config_ware_modal_data.total}条，每页5条`;
    this.limit_count_ware_modal_data = initLimitCountWareModaData();
  }

  // 获取限时抢购数据
  @changeLoading(['is_loading'])
  async fetchLimitTimeList(current: number = 1) {
    this.limit_time_table_data = await queryLimitTime(current);
  }

  // 获取商品分类列表
  async fetchWareCategoryList() {
    this.ware_category_list = await getSimpleCategory();
    this.ware_category_list.unshift({
      id: 0,
      name: '全部',
    });
  }

  // 获取商品列表
  async fetchWareList(current: number = 1, send_data: any = {}) {
    console.log(send_data);
    send_data.config_limit_time = 0;
    send_data.filter_promotion_product = 1;
    const res: any = await queryWare(current, send_data);
    this.modal_ware_data = res;
    console.log(JSON.parse(JSON.stringify(res)));
    this.modal_ware_data.config_limit_time = 0;
  }

  // 以下为限量抢购功能逻辑
  // ======================================================================================

  @Watch('modal_ware_data', { deep: true })
  wareDataAddParentCategory() {
    this.modal_ware_data.detail.forEach((ware: any) => {
      this.ware_category_list.forEach((category: any) => {
        if (ware.category.parent_id === category.id && ware.category.parent_id !== 0) {
          ware.category.parent_name = `${category.name}/`;
        }
      });
    });
  }

  @Watch('limit_count_table_data', { deep: true })
  tableDataAddParentCategory() {
    this.limit_count_table_data.detail.forEach((ware: any) => {
      this.ware_category_list.forEach((category: any) => {
        if (
          ware.product.category.parent_id === category.id &&
          ware.product.category.parent_id !== 0
        ) {
          ware.product.category.parent_name = `${category.name}/`;
        }
      });
    });
  }

  // 清空搜索条件
  resetFilter(type: number) {
    if (type === 1) {
      this.limit_time_filter.activity_time = [];
      this.limit_time_filter.status = '0';
      this.clickSearchBtn();
    } else {
      this.limit_count_form_data = initLimitCountFormData();
      this.searchLimitCount();
    }
  }

  // 获取限量商品数据
  @changeLoading(['is_loading'])
  async getLimitCountData(query_params?: any) {
    const res: any = await getList(query_params);
    this.limit_count_table_data = initLimitCountData();
    if (res.status !== 200) {
      this.$message.error(res.message);
      return;
    }
    this.limit_count_table_data = initLimitCountData(res.data);
    this.selected_data = JSON.parse(JSON.stringify(this.limit_count_table_data.detail));
  }

  @changeLoading(['is_loading'])
  async searchLimitCount() {
    //console.log(this.surplus_number,this.money_true)
    if (this.surplus_number || this.money_true) {
      return;
    } else {
      const start_time = this.limit_count_form_data.time[0]
        ? Math.floor(
            +moment(this.limit_count_form_data.time[0]).startOf('minute').format('x') / 1000
          )
        : '';
      const end_time = this.limit_count_form_data.time[1]
        ? Math.floor(+moment(this.limit_count_form_data.time[1]).endOf('minute').format('x') / 1000)
        : '';
      const query_params = {
        current: 1,
        limit: 20,
        product_id: this.limit_count_form_data.id,
        category_id:
          this.limit_count_form_data.category.length > 0
            ? this.limit_count_form_data.category[this.limit_count_form_data.category.length - 1]
            : '',
        is_show: this.limit_count_form_data.status === '0' ? '' : this.limit_count_form_data.status,
        stock_floor:
          this.limit_count_form_data.stock_start === 0
            ? '0'
            : this.limit_count_form_data.stock_start,
        stock_ceil:
          this.limit_count_form_data.stock_end === 0 ? '0' : this.limit_count_form_data.stock_end,
        start_price:
          this.limit_count_form_data.price_start === 0
            ? '0'
            : this.limit_count_form_data.price_start * 100,
        end_price:
          this.limit_count_form_data.price_end === 0
            ? '0'
            : this.limit_count_form_data.price_end * 100,
        start_time,
        product_title: this.limit_count_form_data.name,
        end_time,
      };
      await this.getLimitCountData(query_params);
    }
  }
  @changeLoading(['is_loading'])
  async limitCountHandleChange(data: any) {
    const start_time = this.limit_count_form_data.time[0]
      ? Math.floor(+moment(this.limit_count_form_data.time[0]).startOf('minute').format('x') / 1000)
      : '';
    const end_time = this.limit_count_form_data.time[1]
      ? Math.floor(+moment(this.limit_count_form_data.time[1]).endOf('minute').format('x') / 1000)
      : '';
    const query_params = {
      current: data.current,
      limit: 20,
      product_id: this.limit_count_form_data.id,
      category_id:
        this.limit_count_form_data.category.length > 0
          ? this.limit_count_form_data.category[this.limit_count_form_data.category.length - 1]
          : '',
      is_show: this.limit_count_form_data.status === '0' ? '' : this.limit_count_form_data.status,
      stock_floor: this.limit_count_form_data.stock_start,
      stock_ceil: this.limit_count_form_data.stock_end,
      start_price: this.limit_count_form_data.price_start,
      end_price: this.limit_count_form_data.end_price,
      start_time,
      product_title: this.limit_count_form_data.name,
      end_time,
    };
    await this.getLimitCountData(query_params);
  }

  // 点击新增限量商品按钮
  @changeLoading(['is_loading'])
  async clickAddLimitCountBtn() {
    this.is_show_ware_modal_first = true;
    await this.fetchWareList(1, {
      config_limit_time: 0,
      page: 5,
      is_vip: 2,
    });
  }

  // 添加限量抢购
  @changeLoading(['is_loading'])
  async batchAddLimitCount() {
    const validate_ware = this.config_ware_modal_data.detail.filter((item: any) => {
      return !item.special_offer || !item.time_in_force;
    });
    if (validate_ware.length) {
      this.$message.error('请填写活动价和生效时间');
      return;
    }
    const send_data = {
      product: [],
    };
    send_data.product = this.config_ware_modal_data.detail.map((item: any) => {
      return {
        product_id: item.id,
        promotion_price: item.special_offer * 100,
        start_time: item.time_in_force.format('X'),
      };
    });
    const res = await addLimitCount(send_data);
    if (res.status !== 200) {
      this.$message.error((res as any).message);
      return;
    }
    this.is_show_ware_modal_second = false;
    await this.getLimitCountData();
    this.$message.success('添加成功');
    this.config_ware_modal_data = {
      detail: [],
      showTotal: '',
      total: 0,
      limit: 5,
      current: 1,
    };
  }

  // 搜索商品
  @changeLoading(['is_loading'])
  async searchLimitCountWare() {
    const send_data: any = {
      page: 5,
      product_id: this.limit_count_ware_modal_data.id,
      product_name: this.limit_count_ware_modal_data.name,
      category_id:
        this.limit_count_ware_modal_data.category[0] === 0
          ? ''
          : this.limit_count_ware_modal_data.category[
              this.limit_count_ware_modal_data.category.length - 1
            ],
      is_show:
        this.limit_count_ware_modal_data.status === 0
          ? ''
          : this.limit_count_ware_modal_data.status,
      config_limit_time: 0,
      is_vip: 2,
    };
    for (const key in send_data) {
      if (!send_data[key]) delete send_data[key];
    }
    await this.fetchWareList(1, send_data);
  }

  @changeLoading(['is_loading'])
  async handleWareModalChange(data: any) {
    console.log(data);
    const send_data: any = {
      product_id: this.limit_count_ware_modal_data.id,
      product_name: this.limit_count_ware_modal_data.name,
      category_id:
        this.limit_count_ware_modal_data.category[0] === 0
          ? ''
          : this.limit_count_ware_modal_data.category[
              this.limit_count_ware_modal_data.category.length - 1
            ],
      is_show:
        this.limit_count_ware_modal_data.status === 0
          ? ''
          : this.limit_count_ware_modal_data.status,
      config_limit_time: 0,
      page: 5,
    };
    for (const key in send_data) {
      if (!send_data[key]) delete send_data[key];
    }
    await this.fetchWareList(data.current, send_data);
  }

  @changeLoading(['is_loading'])
  async handleSortChange(sort: any, event: any, id: any) {
    if (!/^[0-9]+$/.test(event + '')) {
      this.$message.error('排序值须为>=0的整数值！');
      return;
    }
    /* if (event == 0) {
      this.$message.error('排序值不能为0！');
      return;
    } */
    const res = await editSort(id, event);
    if (res.status !== 200) {
      this.$message.error((res as any).message);
      return;
    }
    this.$message.success('更新排序值成功');
    await this.getLimitCountData();
  }

  // 删除限时抢购
  @changeLoading(['is_loading'])
  async deleteLimitCount(id: number) {
    const delete_id_arr: any = [];
    this.limit_count_table_data.detail.forEach((item: any) => {
      if (this.selected_limit_count_row_keys.indexOf(item.id) !== -1) {
        delete_id_arr.push(item.product_id);
      }
    });
    let res;
    if (this.batch_delete) {
      res = await deleteLimitCount(delete_id_arr.join());
    } else {
      res = await deleteLimitCount(this.delete_limit_count_id);
    }
    if (res.status !== 200) {
      this.$message.error((res as any).message);
      return;
    }
    this.$message.success('删除成功');
    await this.getLimitCountData();
    this.selected_limit_count_row_keys = [];
    this.delete_limit_count_id = '';
    this.batch_delete = false;
    this.delete_limit_count_modal = false;
  }

  // 点击删除按钮
  showDeleteLimitCountModal(id: any, type: number) {
    // type = 1 单个删除  2 批量删除
    if (type === 1) {
      this.delete_limit_count_modal = true;
      this.delete_limit_count_id = id;
    } else {
      if (!this.selected_limit_count_row_keys.length) {
        this.$message.error('请勾选商品');
        return;
      }
      this.delete_limit_count_modal = true;
      this.batch_delete = true;
    }
  }

  // 勾选限量商品
  onSelectLimitCountChange(selectedRowKeys: any) {
    this.selected_limit_count_row_keys = selectedRowKeys;
  }

  checkAll() {
    if (this.selected_limit_count_row_keys.length === this.limit_count_table_data.detail.length) {
      this.selected_limit_count_row_keys = [];
    } else {
      this.selected_limit_count_row_keys = this.limit_count_table_data.detail.map((item: any) => {
        return item.id;
      });
    }
  }

  // 编辑活动价
  @changeLoading(['is_loading'])
  async handlePromotionPriceChange(record: any, promotion_price: any, product_id: any) {
    if (Number(promotion_price) >= Number(record.product.price / 100)) {
      this.$message.error('活动价须低于该商品最低价');
      return;
    }
    const res = await editPrice(record.id, promotion_price, product_id);
    if (res.status !== 200) {
      this.$message.error((res as any).message);
      return;
    }
    this.$message.success('编辑成功');
    await this.getLimitCountData();
  }

  tablePagination(data: any) {
    return {
      current: +data.current,
      total: data.total || 0,
      pageSize: data.limit || 0,
      showTotal: () => `共${data.total}条，每页${data.limit}条`,
    };
  }

  // 点击添加商品模态窗取消按钮
  clickSecondCancelBtn() {
    this.is_show_ware_modal_second = false;
    this.is_show_ware_modal_first = false;
    this.config_ware_modal_data = {
      detail: [],
      showTotal: '',
      total: 0,
      limit: 5,
      current: 1,
    };
    this.limit_count_ware_modal_data = initLimitCountWareModaData();
    this.selected_row_keys = [];
  }
  @changeLoading(['is_loading'])
  async created() {
    await this.setGlobalFunction({ fetchLimitTimeList: this.fetchLimitTimeList });
    await this.fetchLimitTimeList();
    await this.fetchWareCategoryList();
    await this.fetchWareList(1, {
      config_limit_time: 0,
    });
  }
  range(start: any, end: any) {
    const result: any = [];
    for (let i = start; i >= end; i--) {
      result.push(i);
    }
    return result;
  }
  //时间验证
  startTime: any = '';
  disabledDate(current: any) {
    // Can not select days before today and today
    //return current && current <= moment().endOf('day');
    const nowDate = new Date();
    this.startTime =
      nowDate.getFullYear() + '/' + (nowDate.getMonth() + 1) + '/' + nowDate.getDate();
    //console.log(this.startTime)
    return current < moment(this.startTime).add(0, 'day');
  }

  disabledDateTime(dates: any) {
    const hours = moment().hours();
    const minutes = moment().minutes();
    const seconds = moment().seconds();
    if (dates && moment(dates).date() === moment().date()) {
      return {
        disabledHours: () => this.range(hours - 1, 0),
        /* disabledMinutes: () => this.range(minutes-1 , 0),
        disabledSeconds: () => this.range(seconds-1 , 0), */
      };
    }
    return {
      disabledHours: () => [],
      disabledMinutes: () => [],
      disabledSeconds: () => [],
    };
  }
  changeTime(val) {
    return moment(val).format('YYYY-MM-DD HH:mm:ss');
  }
}
