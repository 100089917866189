import { request as axios } from '@/util/request';
import { listToMap } from '@/util/global';
import { PaginationData } from '@/api/operation/customer';
const clean = require('bmh/clean-empty-obj');
const getUrl = require('baimahu/url-with-param');
const API = {
  time: '/boss/operation/promotion/time',
  is_cross: '/boss/operation/promotion/cross',
  ware_list: '/boss/operation/promotion/time/product',
};

interface QuerySendData {
  status: number;
  begin: number;
  end: number;
}

interface BaseSendData {
  subtype: number;
  start_time: number;
  end_time: number;
}

interface Product {
  product_id: number;
  sort: number;
  promotion_price: number;
}

interface AddSendData extends BaseSendData {
  product: Product[];
}

interface UpdateSendData extends BaseSendData {
  add_product: Product[];
  edit_product: Product[];
  delete_product_ids: number[];
}

export const ACTIVITY_STATUS = [
  {
    value: '0',
    label: '全部',
  },
  {
    value: '2',
    label: '未开始',
  },
  {
    value: '1',
    label: '进行中',
  },
  {
    value: '3',
    label: '已结束',
  },
];
export const ACTIVITY_STATUS_MAP = listToMap(ACTIVITY_STATUS);

export const LIMIT_COUNT_WARE_STATUS = [
  {
    value: '0',
    label: '全部',
  },
  {
    value: '1',
    label: '上架中',
  },
  {
    value: '2',
    label: '已下架',
  },
];
export const LIMIT_COUNT_WARE_STATUS_MAP = listToMap(LIMIT_COUNT_WARE_STATUS);

function formatLimitTime(remote: any = {}): RemotePagination<LimitTime> {
  return {
    current: remote.current || 1,
    limit: remote.limit || 10,
    detail: remote.detail || [],
    total: remote.total || 0,
    last: remote.last || 1,
  };
}

function formatLimitTimeData(remote: any = {}): LimitTime {
  const status = formatStatus(remote.start_time, remote.end_time);
  return {
    id: remote.id,
    start_time: remote.start_time,
    end_time: remote.end_time,
    status: status,
    status_text: ACTIVITY_STATUS_MAP[status],
    product_count: remote.product_count,
    create_time: remote.create_time,
    update_time: remote.update_time,
  };
}

/**
 * 根据时间来确定活动状态
 * @param start_time 开始时间
 * @param end_time 结束时间
 */
function formatStatus(start_time: number, end_time: number) {
  let status = 0;
  const now = ~~(+Date.now() / 1000);
  if (now < start_time) {
    status = 1;
  }
  if (now >= start_time && now <= end_time) {
    status = 2;
  }
  if (now > end_time) {
    status = 3;
  }
  return status;
}

/**
 * 查询限时抢购列表数据
 * @param current
 * @param send_data
 */
async function queryLimitTime(current: number = 1, send_data?: QuerySendData) {
  clean(send_data);
  const _send_data = {
    ...send_data,
    current,
  };
  const res = await axios.get(API.time, { params: _send_data });
  const limit_time = formatLimitTime(res.data);
  limit_time.detail = limit_time.detail.map((item: any) => formatLimitTimeData(item));
  console.log(limit_time);

  return limit_time;
}

/**
 * 获取单个限时抢购活动的详情
 * @param id 活动 id
 */
async function queryLimitTimeById(id: number) {
  const res = await axios.get(`${API.time}/${id}`);
  // return formatLimitTimeData(res.data);
  return res;
}

// 限时抢购的商品数据接口
export interface LimitTimeWareData {
  id: number;
  product: ProductData[];
  product_id: number;
  sotr: number;
  is_new: boolean;
  is_edit: boolean;
}
interface ProductData {
  category: CategoryData;
  category_id: number;
  config_limit_time: number;
  create_time: number;
  detail_image_url: string;
  goods_parameter: string;
  goods_services: string;
  id: number;
  is_recommend: number;
  is_show: number;
  parameter: any[];
  price: number;
  promotion: Promotion[];
  putaway_time: number;
  putaway_type: number;
  relevance_id: number;
  sales: number;
  sort: number;
  stock: number;
  subtitle: string;
  title: string;
  update_time: number;
  video_url: string;
  viewpager_url: string;
  warehouse: any[];
}
interface Promotion {
  promotion_price: number;
}
interface CategoryData {
  create_time: number;
  id: number;
  level: number;
  name: string;
  parent_id: number;
  sort: number;
  status: number;
  update_time: number;
}
export function initLimitTimeWareData(
  remote?: PaginationData<LimitTimeWareData>
): PaginationData<LimitTimeWareData> {
  remote = remote || ({} as PaginationData<LimitTimeWareData>);
  return {
    current: +remote.current || 0,
    detail: remote.detail || [],
    last: remote.last || 0,
    total: remote.total || 0,
    limit: remote.limit || 0,
  };
}
// 获取限时抢购活动的商品
export async function getWareList(activity_id: number) {
  const url = getUrl(API.ware_list, {
    activity_id,
  });
  return await axios.get(url);
}

// 发送数据接口
interface SendData {
  subtype: number;
  start_time: number;
  end_time: number;
  add_product: ProductData[];
  edit_product: ProductData[];
  delete_product_ids: number[];
}
interface ProductData {
  product_id: number;
  sort: number;
  promotion_price: number;
}
// 编辑限时抢购
export async function editLimitTime(send_data: SendData, id: number) {
  return await axios.put(`${API.time}/${id}`, send_data);
}

/**
 * 新增限时抢购
 * @param send_data
 */
async function addLimitTime(send_data: AddSendData) {
  return await axios.post(API.time, send_data);
}

async function updateLimitTime(id: number, send_data: UpdateSendData) {
  return await axios.put(`${API.time}/${id}`, send_data);
}

/**
 * 删除限时抢购
 * @param id 当前限时抢购活动的 id
 */
async function deleteLimitTime(id: number) {
  return await axios.delete(`${API.time}/${id}`);
}

// 判断活动时间是否冲突 QueryString：start_time、end_time
async function isCross(start_time: number, end_time: number) {
  return await axios.get(`${API.is_cross}?start_time=${start_time}&end_time=${end_time}`);
}

export default queryLimitTime;
export {
  queryLimitTime,
  queryLimitTimeById,
  addLimitTime,
  updateLimitTime,
  deleteLimitTime,
  isCross,
};
